<template>
  <div class="statistics-monitor-cho-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="100" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="采集方式：">
              <Select clearable v-model="searchForm.type" placeholder="请选择">
                <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="上传时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="总胆固醇：">
              <Row>
                <Col span="10">
                  <Form-item prop="cholesterin_start">
                    <Input v-model="searchForm.cholesterin_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="cholesterin_end">
                    <Input v-model="searchForm.cholesterin_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="甘油三脂：">
              <Row>
                <Col span="10">
                  <Form-item prop="trig_start">
                    <Input v-model="searchForm.trig_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="trig_end">
                    <Input v-model="searchForm.trig_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="高密度脂蛋白:">
              <Row>
                <Col span="9">
                  <Form-item prop="hdl_start">
                    <Input v-model="searchForm.hdl_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="9">
                  <Form-item prop="hdl_end">
                    <Input v-model="searchForm.hdl_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="4" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="低密度脂蛋白:">
              <Row>
                <Col span="10">
                  <Form-item prop="ldl_start">
                    <Input v-model="searchForm.ldl_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="ldl_end">
                    <Input v-model="searchForm.ldl_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="choData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const cholesterinSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.cholesterin_end !== '') {
					this.$refs.formValidate.validateField('cholesterin_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.cholesterin_end !== '') {
						this.$refs.formValidate.validateField('cholesterin_end');
					}
					callback();
				}
			}
		};
		const cholesterinLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.cholesterin_start !== '' &&
						Number(value) < Number(this.searchForm.cholesterin_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const trigSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.trig_end !== '') {
					this.$refs.formValidate.validateField('trig_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.trig_end !== '') {
						this.$refs.formValidate.validateField('trig_end');
					}
					callback();
				}
			}
		};
		const trigLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.trig_start !== '' &&
						Number(value) < Number(this.searchForm.trig_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const hdlSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.hdl_end !== '') {
					this.$refs.formValidate.validateField('hdl_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.hdl_end !== '') {
						this.$refs.formValidate.validateField('hdl_end');
					}
					callback();
				}
			}
		};
		const hdlLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.hdl_start !== '' &&
						Number(value) < Number(this.searchForm.hdl_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ldlSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.ldl_end !== '') {
					this.$refs.formValidate.validateField('ldl_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.ldl_end !== '') {
						this.$refs.formValidate.validateField('ldl_end');
					}
					callback();
				}
			}
		};
		const ldlLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.ldl_start !== '' &&
						Number(value) < Number(this.searchForm.ldl_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				cholesterin_start: '',
				cholesterin_end: '',
				trig_start: '',
				trig_end: '',
				hdl_start: '',
				hdl_end: '',
				ldl_start: '',
				ldl_end: '',
				age_start: '',
				age_end: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				cholesterin_start: '',
				cholesterin_end: '',
				trig_start: '',
				trig_end: '',
				hdl_start: '',
				hdl_end: '',
				ldl_start: '',
				ldl_end: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			typeList: [
				{
					id: 0,
					name: '手动',
				},
				{
					id: 1,
					name: 'app',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.choData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '采集方式',
					key: 'type',
					align: 'center',
					render: (h, params) => {
						if (params.row.type === '0') {
							return h('div', '手动');
						} else if (params.row.type === '1') {
							return h('div', 'app');
						} else {
							return h('div', params.row.type);
						}
					},
				},
				{
					title: '总胆固醇',
					key: 'cholesterin',
					align: 'center',
					render: (h, params) => {
						if (params.row.cholesterin === null || params.row.cholesterin === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.cholesterin + 'mmol/L');
						}
					},
				},
				{
					title: '甘油三脂',
					key: 'trig',
					align: 'center',
					render: (h, params) => {
						if (params.row.trig === null || params.row.trig === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.trig + 'mmol/L');
						}
					},
				},
				{
					title: '高密度脂蛋白',
					key: 'hdl',
					align: 'center',
					render: (h, params) => {
						if (params.row.hdl === null || params.row.hdl === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.hdl + 'mmol/L');
						}
					},
				},
				{
					title: '低密度脂蛋白',
					key: 'ldl',
					align: 'center',
					render: (h, params) => {
						if (params.row.ldl === null || params.row.ldl === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.ldl + 'mmol/L');
						}
					},
				},
				{ title: '上传时间', key: 'create_time', align: 'center' },
			],
			choData: [],
			pageTotal: 0,
			ruleValidate: {
				cholesterin_start: [{ validator: cholesterinSmall, trigger: 'blur' }],
				cholesterin_end: [{ validator: cholesterinLarge, trigger: 'blur' }],
				trig_start: [{ validator: trigSmall, trigger: 'blur' }],
				trig_end: [{ validator: trigLarge, trigger: 'blur' }],
				hdl_start: [{ validator: hdlSmall, trigger: 'blur' }],
				hdl_end: [{ validator: hdlLarge, trigger: 'blur' }],
				ldl_start: [{ validator: ldlSmall, trigger: 'blur' }],
				ldl_end: [{ validator: ldlLarge, trigger: 'blur' }],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getChoData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getChoData() {
			statisticsService.getChoData(this.searchForm).then((data) => {
				this.choData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getChoData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.type = this.searchForm.type;
					this.exportForm.cholesterin_start = this.searchForm.cholesterin_start;
					this.exportForm.cholesterin_end = this.searchForm.cholesterin_end;
					this.exportForm.trig_start = this.searchForm.trig_start;
					this.exportForm.trig_end = this.searchForm.trig_end;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
					this.exportForm.hdl_start = this.searchForm.hdl_start;
					this.exportForm.hdl_end = this.searchForm.hdl_end;
					this.exportForm.ldl_start = this.searchForm.ldl_start;
					this.exportForm.ldl_end = this.searchForm.ldl_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getChoData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/cho/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&type=' +
				this.exportForm.type +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&cholesterin_start=' +
				this.exportForm.cholesterin_start +
				'&cholesterin_end=' +
				this.exportForm.cholesterin_end +
				'&trig_start=' +
				this.exportForm.trig_start +
				'&trig_end=' +
				this.exportForm.trig_end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end +
				'&hdl_start=' +
				this.exportForm.hdl_start +
				'&hdl_end=' +
				this.exportForm.hdl_end +
				'&ldl_start=' +
				this.exportForm.ldl_start +
				'&ldl_end=' +
				this.exportForm.ldl_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-monitor-cho-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
